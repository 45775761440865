'use client'

import InfoModal from '../InfoModal'
import styles from './styles.module.css'
import LanguageSwitch from '@components/atoms/LanguageSwitch'
import OutlineButton from '@components/atoms/OutlineButton'
import { useIsMobile } from '@hooks/usIsMobile'
import { useTranslations } from 'next-intl'
import Image from 'next/image'
import { useState } from 'react'
import { useInView } from 'react-intersection-observer'

export default function Header () {
  const { ref, inView } = useInView()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const isMobile = useIsMobile()
  const t = useTranslations('mint')

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  return (
    <section className='relative flex flex-col items-center'>
      <div
        className={`py-9 px-6 w-full flex justify-center ${
          inView ? 'relative' : 'fixed top-0 left-0 z-30 show-from-above'
        }`}
        style={{
          background:
            'linear-gradient(180deg, #0A1F28 50.33%, rgba(10, 31, 40, 0.93) 83.13%, rgba(10, 31, 40, 0) 95.65%)'
        }}
      >
        <div className='w-full flex items-center justify-between max-w-[1100px]'>
          <div className='relative w-fit h-[56px] items-center hidden md:flex'>
            {
              <Image
                priority
                src={'/img/happy_marmots_logo.webp'}
                width={224}
                height={56}
                quality={100}
                alt='Happy marmots'
                className='!max-w-[224px] !w-[224px] !h-[56px]'
              />
            }
          </div>
          <div className='flex items-center justify-between md:justify-end w-full gap-4'>
            <div className='relative w-fit h-fit pt-4 pb-4'>
              <button
                onClick={() => setIsModalOpen(true)}
                className='block w-full md:py-4 md:px-8 text-orange text-[24px] font-londrina font-normal z-10 relative xl:!text-[16px] !px-8 !py-4'
              >
                Mint your NFT
              </button>
              <Image
                src='/img/buttons-border.svg'
                fill
                alt='Hike with us'
                className='!w-full !h-full !max-h-full'
              />
            </div>

            {isMobile
              ? (
                <LanguageSwitch />
              )
              : (
                <div className='flex items-center'>
                  <LanguageSwitch />
                </div>
              )}
          </div>
        </div>
      </div>
      <div
        ref={ref}
        className='mt-24 mb-20 w-full max-w-[90%] md:max-w-[850px]'
      >
        <Image
          src='/img/header-illustration.webp'
          width={1000}
          height={600}
          className='!w-full !h-auto'
          alt='Happy marmots'
        />
      </div>
      <button>
        <Image
          src='/img/slide-down-button.svg'
          width={70}
          height={72}
          alt='Explore the rest of the page'
          className='floating-animation w-10 h-10 md:w-[70px] md:h-[70px]'
        />
      </button>
      <InfoModal show={isModalOpen} onClose={handleCloseModal}>
        <div className='h-full w-full flex flex-col items-center'>
          <h3 className={`text-orange ${styles.mintHeader} pt-5 pb-3 relative`}>
            <Image
              src='/img/closeButton.png'
              width={390}
              height={503}
              alt='Download our whitepaper'
              className={`w-[30px] sm:w-[30px] ${styles.closeButton} pt-5`}
              onClick={() => handleCloseModal()}
            />
            <Image
              src='/img/1.png'
              width={390}
              height={503}
              alt='Download our whitepaper'
              className={`w-[120px] sm:w-[120px] ${styles.flipped}`}
            />
            <p className='text-orange text-[60px] flex items-center pt-8'>
              {t('8')}
            </p>
            <Image
              src='/img/2.png'
              width={390}
              height={503}
              alt='Download our whitepaper'
              className='w-[120px] sm:w-[120px]'
            />
          </h3>
          <ul className='p-8'>
            {[1, 2, 3, 4, 5, 6, 7].map((index) => {
              return (
                <li
                  key={index}
                  className='text-[20px] leading-[48px] pl-1 text-white font-bold'
                >
                  <span className={styles.highlight}>{t(`${index}.1`)}</span>{' '}
                  {t(`${index}.2`)}
                </li>
              )
            })}
          </ul>
          <OutlineButton
            text={'Mint your NFT'}
            link='https://mint.happymarmots.io/'
            longerButton={false}
            className='xl:!text-[30px] !px-8 !py-4'
          />
        </div>
      </InfoModal>
    </section>
  )
}
